//general packages
import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import * as api from "./api";

//okta packages & config
// import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
// import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import { oktaAuthConfig, oktaSignInConfig } from "./config";

//layouts
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import SideBar from "./components/layouts/SideBar";

//pages
// import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import RegisterCareHome from "./components/pages/RegisterCareHome";
import Welcome from "./components/pages/Welcome";
import HomeDashboard from "./components/pages/HomeDashboard";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import NotFound from "./components/pages/NotFound";

import Users from "./components/pages/users/Users";
import UpdateUser from "./components/pages/users/UpdateUser";

//pages for patients
// import Patients from "./components/pages/patients/Patients";
// import PatientPage from "./components/pages/patients/PatientPage";
// import CreatePatient from "./components/pages/patients/CreatePatient";
// import UpdatePatient from "./components/pages/patients/UpdatePatient";
// import AddMedication from "./components/pages/patients/datacategories/AddMedication";
// import ReadMedications from "./components/pages/patients/datacategories/ReadMedications";
// import UpdateMedication from "./components/pages/patients/datacategories/UpdateMedication";

//pages for schedule
// import Schedules from "./components/pages/schedules/Schedules";
// import ProgressNotes from "./components/pages/progress_notes/ProgressNotes";
// import Assessments from "./components/pages/assessments/Assessments";
// import CarePlans from "./components/pages/careplans/CarePlans";
// import Loved1s from "./components/pages/loved1s/Loved1s";
// import UpdateLovedOnes from "./components/pages/loved1s/UpdateLoved1s";

// pages for support
import SupportRequests from "./components/pages/support/SupportRequests";
import CreateTicket from "./components/pages/support/CreateTicket";
import TicketSuccess from "./components/pages/support/TicketSuccess";
import ContactSupport from "./components/pages/support/ContactSupport";

// Landing page
import LandingPage from "./components/pages/landing_page/LandingPage";

// Push Notification
import { db } from "./firebase/firebase";
import { ref, onValue, off } from "firebase/database";
// import { generateToken, messaging } from "./firebase/firebase";
// import { onMessage } from "firebase/messaging";
import toast from "react-hot-toast";

// // Font Awesome Icons
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";
// import { far } from "@fortawesome/free-regular-svg-icons";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// library.add(fab, far, fas);

// import AddProgressNotes from "./components/pages/progress_notes/AddProgressNotes";
// import DeletePatient from "./components/pages/patients/DeletePatient";
// import TermsAndConditions from "./components/layouts/TermsAndConditions";
// import Handover from "./components/pages/handovers/Handover";
// import NoHandovers from "./components/pages/NoHandovers";
// import AddVisit from "./components/pages/visits/AddVisit";
// import VisitDetails from "./components/pages/visits/VisitDetails";
// import ActivityDetails from "./components/pages/visits/ActivityDetails";
// import Handovers from "./components/pages/handovers/Handovers";
//this one uses your Okta details like domain and clientId so it can be used in the login widget
// const oktaAuth = new OktaAuth(oktaAuthConfig);

const Login = React.lazy(() => import("./components/pages/Login"));
const ForgotPassword = React.lazy(() =>
  import("./components/pages/ForgotPassword")
);

// Patients
const Patients = React.lazy(() =>
  import("./components/pages/patients/Patients")
);
const PatientPage = React.lazy(() =>
  import("./components/pages/patients/PatientPage")
);
const CreatePatient = React.lazy(() =>
  import("./components/pages/patients/CreatePatient")
);
const UpdatePatient = React.lazy(() =>
  import("./components/pages/patients/UpdatePatient")
);
const AddMedication = React.lazy(() =>
  import("./components/pages/patients/datacategories/AddMedication")
);
const ReadMedications = React.lazy(() =>
  import("./components/pages/patients/datacategories/ReadMedications")
);
const UpdateMedication = React.lazy(() =>
  import("./components/pages/patients/datacategories/UpdateMedication")
);

// Schedules
const Schedules = React.lazy(() =>
  import("./components/pages/schedules/Schedules")
);

const Tasks = React.lazy(() => import("./components/pages/tasks/Tasks"));

const ProgressNotes = React.lazy(() =>
  import("./components/pages/progress_notes/ProgressNotes")
);
const Assessments = React.lazy(() =>
  import("./components/pages/assessments/Assessments")
);
const CarePlans = React.lazy(() =>
  import("./components/pages/careplans/CarePlans")
);
const Loved1s = React.lazy(() => import("./components/pages/loved1s/Loved1s"));

const ResidentLoved1s = React.lazy(() =>
  import("./components/pages/loved1s/ResidentLoved1s")
);

const PracticeThread = React.lazy(() =>
  import("./components/pages/loved1s/PracticeThread")
);

const ThreadLists = React.lazy(() =>
  import("./components/pages/loved1s/ThreadLists")
);

const LovedOneThread = React.lazy(() =>
  import("./components/pages/loved1s/LovedOneThread")
);

const UpdateLovedOnes = React.lazy(() =>
  import("./components/pages/loved1s/UpdateLoved1s")
);

const AddProgressNotes = React.lazy(() =>
  import("./components/pages/progress_notes/AddProgressNotes")
);
const DeletePatient = React.lazy(() =>
  import("./components/pages/patients/DeletePatient")
);
const TermsAndConditions = React.lazy(() =>
  import("./components/layouts/TermsAndConditions")
);
const Handover = React.lazy(() =>
  import("./components/pages/handovers/Handover")
);
const NoHandovers = React.lazy(() => import("./components/pages/NoHandovers"));
const AddVisit = React.lazy(() => import("./components/pages/visits/AddVisit"));
const ViewVisits = React.lazy(() =>
  import("./components/pages/visits/ViewVisits")
);
const FacilityVisitDetails = React.lazy(() =>
  import("./components/pages/visits/FacilityVisitDetails")
);
const Treatments = React.lazy(() =>
  import("./components/pages/treatments/Treatments")
);

const VisitDetails = React.lazy(() =>
  import("./components/pages/visits/VisitDetails")
);
const ActivityDetails = React.lazy(() =>
  import("./components/pages/visits/ActivityDetails")
);
const Handovers = React.lazy(() =>
  import("./components/pages/handovers/Handovers")
);

// imports for allergies page
const Allergies = React.lazy(() =>
  import("./components/pages/allergies/Allergies")
);
const Medications = React.lazy(() =>
  import("./components/pages/medications/Medications")
);
const AddConsent = React.lazy(() =>
  import("./components/pages/consents/AddConsent")
);
const ConsentLists = React.lazy(() =>
  import("./components/pages/consents/ConsentLists")
);

const RequestConsent = React.lazy(() =>
  import("./components/pages/consents/RequestConsent")
);

const ReleaseNotes = React.lazy(() =>
  import("./components/pages/release_notes/ReleaseNotes")
);

const Referrals = React.lazy(() =>
  import("./components/pages/referrals/Referral")
);

const Referrallists = React.lazy(() =>
  import("./components/pages/referrals/ReferralLists")
);

const GeneralCareHome = React.lazy(() =>
  import("./components/pages/carehome/GeneralCareHome")
);

const PodiatryProgressNotes = React.lazy(() =>
  import("./components/pages/facilities/podiatry/PodiatryProgressNotes")
);

const PodiatryAssessments = React.lazy(() =>
  import("./components/pages/facilities/podiatry/PodiatryAssessments")
);

const PodiatryCarePlans = React.lazy(() =>
  import("./components/pages/facilities/podiatry/PodiatryCarePlans")
);

const PhysioProgressNotes = React.lazy(() =>
  import("./components/pages/facilities/physiotherapy/PhysioProgressNotes")
);

const PhysioAssessments = React.lazy(() =>
  import("./components/pages/facilities/physiotherapy/PhysioAssessments")
);

const DieteticsProgressNotes = React.lazy(() =>
  import("./components/pages/facilities/dietetics/DieteticsProgressNotes")
);

const DieteticsAssessments = React.lazy(() =>
  import("./components/pages/facilities/dietetics/DieteticsAssessments")
);

const ChatPage = React.lazy(() => import("./components/pages/chats/Chat"));

const AllSettings = React.lazy(() =>
  import("./components/pages/settings/AllSettings")
);

const Notifications = React.lazy(() =>
  import("./components/pages/notifications/Notifications")
);

const RegisterAccount = React.lazy(() =>
  import("./components/pages/accountManagement/RegisterAccount")
);

const AccountLists = React.lazy(() =>
  import("./components/pages/accountManagement/AccountLists")
);

const Subscriptions = React.lazy(() =>
  import("./components/pages/subscriptions/Subscriptions")
);

const Validate = React.lazy(() => import("./Validate"));
const ProcessBookingConfirmation = React.lazy(() =>
  import("./components/booking/ProcessBooking")
);
const MultiProcessBookingConfirmation = React.lazy(() =>
  import("./components/booking/MultiProcessBooking")
);
const SuccessBookingConfirmation = React.lazy(() =>
  import("./components/booking/SuccessBooking")
);
const CancelBookingConfirmation = React.lazy(() =>
  import("./components/booking/CancelBooking")
);
const SetResidentActive = React.lazy(() =>
  import("./components/resident-status/SetActive")
);
const SetResidentInActive = React.lazy(() =>
  import("./components/resident-status/SetInActive")
);
const MultiStatusManager = React.lazy(() =>
  import("./components/resident-status/MultiStatusManager")
);
const LovedOnePayment = React.lazy(() =>
  import("./components/booking/LovedOnePayment")
);

function AppWithRouterAccess() {
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  let history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [isNavClosed, setIsNavClosed] = useState(false);
  const [saved, setSaved] = useState(true);
  const [confirmShowed, setConfirmShowed] = useState(false);
  const [sort, setSort] = useState("");
  const location = useLocation();
  let param =
    location?.search?.split("&")?.filter((item) => item !== "") ?? null;
  const [currentPage, setCurrentPage] = useState(
    parseInt(param[0]?.split("=")[1] ?? "0") === 0
      ? null
      : parseInt(param[0]?.split("=")[1])
  );
  const [navbarLoader, setNavBarLoader] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [unreadCount, setUnreadCount] = useState(false);

  //this one forwards to login page when a secure route is accessed
  // const customAuthHandler = () => {
  //   history.push("/login");
  // };

  // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  //   if (originalUri) {
  //     history.replace(toRelativeUrl(originalUri, window.location.origin));
  //   }
  // };

  const changeURLPatient = (
    patientId,
    page,
    tabOpen,
    facility,
    filter,
    ward,
    funding,
    date = null
  ) => {
    if (confirmShowed) return;
    if (!saved) {
      setConfirmShowed(true);
      if (
        !window.confirm(
          "Are you sure you want to leave this page? Unsaved changes will be discarded"
        )
      ) {
        setConfirmShowed(false);
        return false;
      }
      setConfirmShowed(false);
    }
    setSaved(true);
    history.replace({
      search:
        `?page=${page ?? 1}&patientId=${patientId ?? "null"}&tab=${
          tabOpen ?? "1"
        }&facility=${
          facility && facility.length > 0 ? facility.join(",") : "null"
        }&filter=${filter ?? "today"}&ward=${
          ward && ward.length > 0 ? ward.join(",") : "null"
        }&funding=${funding ?? "null"}` + (date != null ? `&date=${date}` : ""),
    });

    return true;
  };

  // for push notification
  const getNotifications = async () => {
    try {
      setNotificationLoader(true);
      const res = await api.getPushNotification(accessToken);
      const notif = res.data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setNotificationData(notif);
      setNotificationLoader(false);
    } catch (error) {
      console.log(error);
      setNotificationLoader(false);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [accessToken]);

  // useEffect(() => {
  //   generateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log(payload);
  //     setNotificationData((prevArray) => [payload, ...prevArray]);
  //     toast(payload.data.event + " - " + payload.data.notes);
  //   });
  // }, []);
  if (window.location.pathname.includes("/releaseNotes/")) {
    return (
      <Route path="/releaseNotes/:platform" component={ReleaseNotes}></Route>
    );
  }
  return (
    <div>
      {/* <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    > */}
      {!window.location.pathname.includes("/consent") &&
        !window.location.pathname.includes("/validate") && (
          <Navbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            sort={sort}
            setSort={setSort}
            changeURLPatient={changeURLPatient}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setNavBarLoader={setNavBarLoader}
            selectedOption={selectedOption}
            notificationData={notificationData}
            setNotificationData={setNotificationData}
            notificationLoader={notificationLoader}
            unreadCount={unreadCount}
            setUnreadCount={setUnreadCount}
          />
        )}

      <div className="container-fluid hapi-main-body pt-0 mb-0 pb-0">
        <div className="row">
          {window.location.pathname.includes("/consent") ||
          window.location.pathname.includes(
            "/validate"
          ) ? null : !isNavClosed ? (
            <div className="col-md-4 col-lg-3 col-xl-2 d-sm-none d-sm-block p-0 m-0 bg-white hapi-sidebar position-relative">
              {/* Note that there are Links <NavLink /> inside the sidebar below and is covered by Browser/Router and Security above */}
              <SideBar
                setIsNavClosed={setIsNavClosed}
                saved={saved}
                setSaved={setSaved}
              />
            </div>
          ) : (
            <div
              className="position-fixed"
              style={{ top: "140px", zIndex: 100 }}
            >
              <button
                className="btn btn-primary bg-light orange-1 py-4 border border-primary border-left-0"
                style={{
                  borderTopRightRadius: "25px",
                  borderBottomRightRadius: "25px",
                }}
                onClick={() => setIsNavClosed(false)}
              >
                <i className="fas fa-bars"></i>
              </button>
            </div>
          )}

          <div
            className={`${
              isNavClosed ||
              window.location.pathname.includes("/consent") ||
              window.location.pathname.includes("/validate")
                ? "col-md-12"
                : "col-md-8 col-lg-9 col-xl-10"
            } hapi-main-content`}
          >
            {/* For switching between pages */}
            <Switch>
              {/* Private Routes */}
              <PrivateRoute exact path="/">
                <LandingPage
                  notificationData={notificationData}
                  notificationLoader={notificationLoader}
                />
              </PrivateRoute>
              <PrivateRoute exact path="/landing-page">
                <LandingPage
                  notificationData={notificationData}
                  notificationLoader={notificationLoader}
                />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard">
                <HomeDashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/users">
                <Users />
              </PrivateRoute>
              <PrivateRoute exact path="/users/edit/:_id">
                <UpdateUser />
              </PrivateRoute>
              <PrivateRoute exact path="/patients">
                <Patients
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  saved={saved}
                  setSaved={setSaved}
                  changeURLPatient={changeURLPatient}
                  confirmShowed={confirmShowed}
                  setConfirmShowed={setConfirmShowed}
                  sort={sort}
                  setSort={setSort}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  navbarLoader={navbarLoader}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                />
              </PrivateRoute>
              <PrivateRoute exact path="/patient">
                <PatientPage
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  saved={saved}
                  setSaved={setSaved}
                  changeURLPatient={changeURLPatient}
                  confirmShowed={confirmShowed}
                  setConfirmShowed={setConfirmShowed}
                />
              </PrivateRoute>
              <PrivateRoute exact path="/schedules">
                <Schedules />
              </PrivateRoute>
              <PrivateRoute exact path="/tasks">
                <Tasks />
              </PrivateRoute>
              <PrivateRoute exact path="/loved1s">
                <Loved1s />
              </PrivateRoute>
              <PrivateRoute exact path="/loved1s/:_id">
                <ResidentLoved1s />
              </PrivateRoute>
              <PrivateRoute exact path="/loved1s/add">
                <UpdateLovedOnes />
              </PrivateRoute>
              <PrivateRoute exact path="/threads/:threadId">
                <PracticeThread />
              </PrivateRoute>
              <PrivateRoute exact path="/threadLists/:residentId">
                <ThreadLists />
              </PrivateRoute>
              <PrivateRoute exact path="/handovers/visitId=:visitId">
                <Handover />
              </PrivateRoute>
              <PrivateRoute exact path="/handovers">
                <Handovers />
              </PrivateRoute>
              <PrivateRoute exact path="/visits/addVisit">
                <AddVisit />
              </PrivateRoute>
              <PrivateRoute exact path="/visits">
                <ViewVisits />
              </PrivateRoute>
              <PrivateRoute exact path="/treatments">
                <Treatments />
              </PrivateRoute>
              <PrivateRoute exact path="/visits/visitDetails">
                <VisitDetails />
              </PrivateRoute>
              <PrivateRoute exact path="/visits/visitDetails/finalize">
                <FacilityVisitDetails />
              </PrivateRoute>
              <PrivateRoute exact path="/visits/activityDetails/:residentId">
                <ActivityDetails />
              </PrivateRoute>
              <PrivateRoute exact path="/support">
                <SupportRequests />
              </PrivateRoute>
              <PrivateRoute exact path="/support/contact">
                <ContactSupport />
              </PrivateRoute>
              <PrivateRoute exact path="/support/add">
                <CreateTicket />
              </PrivateRoute>
              <PrivateRoute exact path="/support/success">
                <TicketSuccess />
              </PrivateRoute>
              <PrivateRoute exact path="/patients/add">
                <CreatePatient />
              </PrivateRoute>
              <PrivateRoute exact path="/patients/edit/:_id">
                <UpdatePatient />
              </PrivateRoute>
              <PrivateRoute exact path="/patients/delete/:_id">
                <DeletePatient />
              </PrivateRoute>
              <PrivateRoute exact path="/addConsent/:_id">
                <AddConsent />
              </PrivateRoute>
              <PrivateRoute exact path="/allConsents/:_id">
                <ConsentLists />
              </PrivateRoute>
              <PrivateRoute exact path="/patients/medications/add/:_id">
                <AddMedication />
              </PrivateRoute>
              <PrivateRoute exact path="/patients/medications/:_id">
                <ReadMedications />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/patients/:_id/medications/:_medId/edit"
              >
                <UpdateMedication />
              </PrivateRoute>
              <PrivateRoute exact path="/progress/add/:_id/">
                <AddProgressNotes />
              </PrivateRoute>
              <PrivateRoute exact path="/allergies/edit/">
                <Allergies />
              </PrivateRoute>
              <PrivateRoute exact path="/medications/edit/">
                <Medications />
              </PrivateRoute>
              <PrivateRoute exact path="/terms">
                <TermsAndConditions />
              </PrivateRoute>
              <PrivateRoute exact path="/contact">
                <Contact />
              </PrivateRoute>

              <PrivateRoute exact path="/handover/visitId=:visitId">
                <NoHandovers />
              </PrivateRoute>
              <PrivateRoute exact path="/referrals/:_id">
                <Referrals />
              </PrivateRoute>
              <PrivateRoute exact path="/referralLists/:_id">
                <Referrallists />
              </PrivateRoute>
              <PrivateRoute exact path="/generalCareHome/:_id">
                <GeneralCareHome />
              </PrivateRoute>
              <PrivateRoute exact path="/facilities/podiatry/progressNotes">
                <PodiatryProgressNotes />
              </PrivateRoute>
              <PrivateRoute exact path="/facilities/podiatry/assessments">
                <PodiatryAssessments />
              </PrivateRoute>
              <PrivateRoute exact path="/facilities/podiatry/carePlans">
                <PodiatryCarePlans />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/facilities/physiotherapy/progressNotes"
              >
                <PhysioProgressNotes />
              </PrivateRoute>
              <PrivateRoute exact path="/facilities/physiotherapy/assessments">
                <PhysioAssessments />
              </PrivateRoute>
              <PrivateRoute exact path="/facilities/dietetics/progressNotes">
                <DieteticsProgressNotes />
              </PrivateRoute>
              <PrivateRoute exact path="/facilities/dietetics/assessments">
                <DieteticsAssessments />
              </PrivateRoute>
              <PrivateRoute exact path="/chat">
                <ChatPage />
              </PrivateRoute>
              <PrivateRoute exact path="/settings">
                <AllSettings />
              </PrivateRoute>
              <PrivateRoute exact path="/notifications">
                <Notifications />
              </PrivateRoute>
              <PrivateRoute exact path="/account/register">
                <RegisterAccount />
              </PrivateRoute>
              <PrivateRoute exact path="/account/lists">
                <AccountLists />
              </PrivateRoute>
              <PrivateRoute exact path="/subscriptions">
                <Subscriptions />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/register-carehome">
                <RegisterCareHome />
              </PrivateRoute>

              {/* Public Routes */}
              <Route
                path="/releaseNotes/:platform"
                component={ReleaseNotes}
              ></Route>

              <Route exact path="/welcome" component={Welcome} />
              <Route exact path="/about" component={About} />
              <Route exact path="/login" component={Login} />
              <Route path="/consent/:_id" component={RequestConsent} />
              <Route
                path="/threads/:contactId/:threadId"
                component={LovedOneThread}
              />
              <Route path="/validate" component={Validate} />
              <Route path="/sign-up" component={Signup} />

              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route
                path="/process-booking"
                component={ProcessBookingConfirmation}
              />
              <Route
                path="/multi-process-booking"
                component={MultiProcessBookingConfirmation}
              />
              <Route
                path="/payment-success"
                component={SuccessBookingConfirmation}
              />
              <Route
                path="/payment-cancel"
                component={CancelBookingConfirmation}
              />
              <Route path="/set-active" component={SetResidentActive} />
              <Route path="/set-inactive" component={SetResidentInActive} />
              <Route
                path="/multi-status-management"
                component={MultiStatusManager}
              />
              <Route path="/lovedone-payments" component={LovedOnePayment} />
              <Route exact component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>

      <Footer />
      {/* </Security> */}
    </div>
  );
}

export default AppWithRouterAccess;
